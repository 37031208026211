








































import { Component, Vue } from 'vue-property-decorator';
import KuspitAccountLinkingForgetPasswordViewModel
  from '@/vue-app/view-models/components/contract-savings/kuspit-account-linking/kuspit-account-linking-forget-password-view-model';

@Component({ name: 'kuspitAccountLinkingForgetPassword' })
export default class KuspitAccountLinkingForgetPassword extends Vue {
  kuspit_forget_password_view_model = Vue.observable(
    new KuspitAccountLinkingForgetPasswordViewModel(this),
  );
}
