import Vue from 'vue';
import TYPES from '@/types';

// Domain
import Inject from '@/modules/shared/domain/di/inject';
import Translator from '@/modules/shared/domain/i18n/translator';
import { Values } from '@/modules/shared/domain/i18n/types';

export default class KuspitAccountLinkingForgetPasswordViewModel {
  @Inject(TYPES.I18N)
  readonly translator!: Translator;

  private readonly view!: Vue;

  i18n_namespace = 'components.contract-savings.kuspit-account-linking.account_linking_forget_password';

  public constructor(view: Vue) {
    this.view = view;
  }

  translate = (message: string, values?: Values) => this.translator.translate(`${this.i18n_namespace}.${message}`, values);

  endProcess= () => {
    this.view.$emit('endProcess');
  }

  goToKuspit = () => {
    window.open('https://kuspit.com/', '_blank');
    this.view.$emit('endProcess');
  }
}
